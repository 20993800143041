import React from 'react';

import {Body, Header3} from "../../styled/typography/Typography";
import {
    PartnersTableDetails,
    PartnersTableHeader,
    PartnersTableWrapper
} from "../../styled/pages/about/partners/PartnersTableSectionComponents";

const PartnersTableSection = ({avatar, title, partners}) => {
    return (
        <PartnersTableWrapper>
            <PartnersTableHeader>
                <img src={avatar.url} alt={title}/>
                <Header3 color="white">
                    {title}
                </Header3>
            </PartnersTableHeader>
            <PartnersTableDetails>
                {partners.map((item, index) => (
                    <div key={index}>
                        <Body color="primary-brand" bold small marginBottom="smaller">{item.name}</Body>
                        <Body color="white" small>{item.address}</Body>
                        <Body color="white" small>{item.tel}</Body>
                        <Body color="white" small><a href={"https://" + item.website}>{item.website}</a></Body>
                    </div>
                ))}
            </PartnersTableDetails>
        </PartnersTableWrapper>
    )
};

export default PartnersTableSection;