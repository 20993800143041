import styled from "styled-components";

export const PartnersTableWrapper = styled.div`
  display: grid;
  padding: 40px;
`

export const PartnersTableHeader = styled.div`
  display: grid;
  grid-template-columns: 70px auto;
  grid-auto-flow: column;
  align-items: center;
  
  img {
    align-self: center;
    justify-self: center;
    height: 2.5rem;
    width: auto;
  }
`

export const PartnersTableDetails = styled.div`
  display: grid;
  padding: 15px 10px 15px 10px;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px 30px;

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
  }
`

