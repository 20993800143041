import React from "react";

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";
import Card from "../../cards/Card";

import {Body, Header2} from "../../styled/typography/Typography";
import Col from "../../styled/grid/Col";
import {ButtonContainer, ButtonLink} from "../../styled/button/ButtonComponents";

const PartnersCard = ({big_title, description, card, button}) => {

    return (
        <SectionLayoutGrid id={"program"}>
            <Col>
                <Header2 color="white" marginBottom="small">
                    {big_title}
                </Header2>
                {description &&
                <Body color="body-text" marginBottom="small">
                    {description}
                </Body>
                }
            </Col>
            {card && card.map((item, index) => {
                if (card.length % 2 === 1 && index === card.length - 1) {
                    return (
                        <React.Fragment key={index}>
                            <Col span={3}/>
                            <Col span={6} grid>
                                <Card
                                    avatar={item.avatar.url}
                                    title={item.title}
                                    description={item.description}
                                />
                            </Col>
                        </React.Fragment>
                    )
                } else {
                    return (
                        <Col span={6} grid key={index}>
                            <Card
                                avatar={item.avatar.url}
                                title={item.title}
                                description={item.description}
                            />
                        </Col>
                    )
                }
            })}
            <Col grid center>
                <ButtonContainer>
                    <ButtonLink to={button[0].link} color="primary-brand">
                        {button[0].name}
                    </ButtonLink>
                </ButtonContainer>
            </Col>
        </SectionLayoutGrid>
    )
}

export default PartnersCard
