import React from "react";
import {graphql} from "gatsby";

import Layout from "/src/components/layouts/Layout"
import PartnersHeader from "/src/components/pages/about/PartnersHeader"
import PartnersTable from "/src/components/pages/about/PartnersTable"
import PartnersCard from "/src/components/pages/about/PartnersCards"

const Partners = ({data}) => {

    const {partners} = data;

    return (
        <Layout lang={partners.lang} seo={partners.SEO}>
            <PartnersHeader
                title_white={partners.partners.title_white}
                title_color={partners.partners.title_color}
                description={partners.partners.description}
                url={partners.partners.button[0]}
                url2={partners.partners.button[1]}
                brand_style="primary-brand"
            />
            <PartnersTable
                title={partners.sections_title}
                diamond={partners.diamond}
                gold={partners.gold}
                silver={partners.silver}
            />
            <PartnersCard
                big_title={partners.program_title}
                description={partners.description}
                card={partners.cards}
                button={partners.cards_button}
            />
        </Layout>
    )
}

export const query = graphql`
   query GetSinglePartners($locale: String) {
         partners: strapiPartners(lang: { eq: $locale }) {
          lang
          partners {
            title_white
            title_color
            description
            button {
              id
              link
              name
            }
          }
          sections_title
          diamond {
            title
            avatar {
              url
            }
            partners {
              id
              address
              name
              tel
              website
            }
          }
          gold {
            avatar {
              url
            }
            title
            partners {
              id
              address
              name
              tel
              website
            }
          }
          silver {
            title
            avatar {
              url
            }
            partners {
              id
              address
              name
              tel
              website
            }
          }
          program_title
          description
          cards {
            id
            title
            description
            avatar {
              url
            }
          }
          cards_button {
            id
            link
            name
          }
          SEO {
                title
                isIndexable
                description
                keywords
                preview {
                  url
                }
            }
      }
   }
 `

export default Partners;