import React from "react";
import Lottie from "lottie-react";

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";

import {LottieAnimation} from "../../styled/lotti/LottieAnimations";
import Col from "../../styled/grid/Col";
import {Body, Span, Title} from "../../styled/typography/Typography";
import {ButtonContainer, ButtonLink} from "../../styled/button/ButtonComponents";
import {OnlyDesktop, OnlyMobile} from "../../styled/views/MobileOrDesktop";

import earthAnimation from "/static/assets/animations/earth.json";
import circuit from "/static/assets/animations/circuit/circuit01.json";

const PartnersHeader = ({
                            title_white,
                            title_color,
                            brand_style,
                            description,
                            url,
                            url2
                        }) => {

    return (
        <>
            <LottieAnimation animationData={circuit} left="true"/>
            <SectionLayoutGrid id={title_color} customBackground="middle">
                <Col span={6} center>
                    <Title color="white" marginBottom="normal">
                        {title_white}<br/>
                        <Span color={brand_style}>{title_color}</Span>
                    </Title>
                    <OnlyMobile>
                        <Lottie animationData={earthAnimation}/>
                    </OnlyMobile>
                    {description &&
                    <Body color="body-text" marginBottom="small">
                        {description}
                    </Body>
                    }
                    {(url || url2) &&
                    <ButtonContainer toolong="true">
                        <ButtonLink to={url.link} color={brand_style} toolong="true">
                            {url.name}
                        </ButtonLink>
                        <ButtonLink to={url2.link} color="secondary-brand" toolong="true">
                            {url2.name}
                        </ButtonLink>
                    </ButtonContainer>
                    }
                </Col>
                <Col span={6} grid center>
                    <OnlyDesktop>
                        <Lottie animationData={earthAnimation}/>
                    </OnlyDesktop>
                </Col>
            </SectionLayoutGrid>
        </>
    )
}

export default PartnersHeader