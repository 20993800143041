import React from "react";

import SectionLayoutGrid from "../../layouts/SectionLayoutGrid";
import PartnersTableSection from "./PartnersTableSection";

import {Header2} from "../../styled/typography/Typography";
import Col from "../../styled/grid/Col";

const PartnersTable = ({
                           title,
                           diamond,
                           gold,
                           silver,
                       }) => {

    return (
        <SectionLayoutGrid customBackground="middle">
            <Col>
                <Header2 color="white" marginBottom="small">
                    {title}
                </Header2>
                <PartnersTableSection partners={diamond.partners} avatar={diamond.avatar} title={diamond.title}/>
                <PartnersTableSection partners={gold.partners} avatar={gold.avatar} title={gold.title}/>
                <PartnersTableSection partners={silver.partners} avatar={silver.avatar} title={silver.title}/>
            </Col>
        </SectionLayoutGrid>
    )
}

export default PartnersTable;